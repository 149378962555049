












































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class P0115 extends Vue {}
