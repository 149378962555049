














































































































































/**
 * セキュリティ観点ではパスワード更新時に現在のパスワードを入力させるが、
 * Auth0は現在のパスワードを検証するエンドポイントを持っていないため、
 * エンドユーザには新しいパスワードのみ入力を求める仕様としている。
 * https://support.auth0.com/tickets/00443654
 */
import { Component, Vue } from 'vue-property-decorator';
import * as api from '../gen/api';
import label from '@/resources/labels/p0005label.json';
import Message from '@/common/message';

@Component({
  components: {}
})
export default class P0005 extends Vue {
  private errMsg: string = this.$msg.get('2000004');
  private label: any = label;
  private newPassword: string = '';
  private newPasswordConfirm: string = '';
  private isPassVisible: boolean = false;
  private isPassConfirmVisible: boolean = false;
  private hasError: boolean = false;
  private visibleErrMsg: boolean = false;
  private readonly passRegexp = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,}$|^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[a-zA-Z\d!-~]{8,}$/;

  private async updatePassword() {
    this.visibleErrMsg = false;
    const result = await this.$validator.validateAll();
    if (result) {
      const config = await this.$getConfigWithToken(this.$auth);
      const curUserApi = new api.CurrentUserApi(config);
      try {
        await curUserApi
          .updateCurrentUserPassword({ password: this.newPassword })
          .catch(this.$tokenErrHandler);
        const returnTo = `${window.location.origin}/update-password-ok`;
        this.$auth.logout({
          returnTo: returnTo,
          opAppLogout: true,
          opAppLogoutMessage: Message.get('2000510')
        });
      } catch (error) {
        this.visibleErrMsg = true;
      }
    }
  }
  /**
   * 戻るボタン押下
   */
  private back(): void {
    // 入力画面に遷移
    this.$router.push('/account-info');
  }
}
