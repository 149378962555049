var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"gBody"},[_c('div',{staticClass:"gBody_contents"},[_c('div',{staticClass:"mv mv-mb"},[_vm._m(0),_c('div',{staticClass:"mv_bgDotted"}),_c('svg',{staticClass:"mv_mask"},[_c('use',{attrs:{"xlink:href":"#SvgVisMask"}})]),_c('svg',{staticClass:"mv_mask mv_mask-color"},[_c('use',{attrs:{"xlink:href":"#SvgVisMaskColor"}})])]),_c('div',{staticClass:"gBody_inner u-inner"},[_c('div',{staticClass:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword($event)}}},[(_vm.visibleErrMsg)?_c('div',{staticClass:"errField"},[_c('p',{staticClass:"errField_text"},[_vm._v(" "+_vm._s(_vm.errMsg)+" ")])]):_vm._e(),_c('p',{staticClass:"form_text"},[_vm._v(" ご希望の新しいパスワードを入力してください。 ")]),_c('div',{staticClass:"form_field"},[_vm._m(1),_c('div',{staticClass:"form_input form_input-short"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, regex: _vm.passRegexp }),expression:"{ required: true, regex: passRegexp }"}],ref:"newPassword",attrs:{"name":"newPassword","data-vv-as":_vm.label.password,"append-icon":_vm.isPassVisible ? 'visibility' : 'visibility_off',"type":_vm.isPassVisible ? 'text' : 'password',"error-messages":_vm.errors.first('newPassword'),"data-withdesk-invisible":""},on:{"click:append":function($event){_vm.isPassVisible = !_vm.isPassVisible}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('button',{class:_vm.isPassVisible
                    ? 'js-visiblePassBtn form_input_eye form_input_eye-up isShow'
                    : 'js-visiblePassBtn form_input_eye form_input_eye-up',attrs:{"type":"button","aria-label":"パスワード切り替え","tabindex":"-1"},on:{"click":function($event){_vm.isPassVisible = !_vm.isPassVisible}}},[_c('svg',{staticClass:"icon icon-eye"},[_c('use',{attrs:{"xlink:href":"#icon-eye"}})]),_c('svg',{staticClass:"icon icon-eyeBlock"},[_c('use',{attrs:{"xlink:href":"#icon-eyeBlock"}})])])],1)]),_c('div',{staticClass:"form_field"},[_c('label',{staticClass:"form_lbl u-ttlBar",attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.label.passwordConfirm))]),_c('div',{staticClass:"form_input form_input-short"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  regex: _vm.passRegexp,
                  confirmed: _vm.newPassword
                }),expression:"{\n                  required: true,\n                  regex: passRegexp,\n                  confirmed: newPassword\n                }"}],attrs:{"name":"newPasswordConfirm","append-icon":_vm.isPassConfirmVisible ? 'visibility' : 'visibility_off',"data-vv-as":_vm.label.password,"type":_vm.isPassConfirmVisible ? 'text' : 'password',"error-messages":_vm.errors.first('newPasswordConfirm'),"data-withdesk-invisible":""},on:{"click:append":function($event){_vm.isPassConfirmVisible = !_vm.isPassConfirmVisible}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}}),_c('button',{class:_vm.isPassConfirmVisible
                    ? 'js-visiblePassBtn form_input_eye form_input_eye-up isShow'
                    : 'js-visiblePassBtn form_input_eye form_input_eye-up',attrs:{"type":"button","aria-label":"パスワード切り替え","tabindex":"-1"},on:{"click":function($event){_vm.isPassConfirmVisible = !_vm.isPassConfirmVisible}}},[_c('svg',{staticClass:"icon icon-eye"},[_c('use',{attrs:{"xlink:href":"#icon-eye"}})]),_c('svg',{staticClass:"icon icon-eyeBlock"},[_c('use',{attrs:{"xlink:href":"#icon-eyeBlock"}})])]),_vm._m(2)],1)]),_c('div',{staticClass:"form_sect"},[_c('div',{staticClass:"form_sect_inner"},[_c('ul',{staticClass:"form_btnList form_btnList-single"},[_c('li',[_c('button',{staticClass:"u-btn",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.label.updateButton)),_c('i',{staticClass:"icon icon-linkRight"})])]),_c('li',[_c('button',{staticClass:"u-btnLine",attrs:{"type":"button"},on:{"click":_vm.back}},[_vm._v(" 戻る"),_c('i',{staticClass:"icon icon-linkLeft"})])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mv_inner u-inner"},[_c('div',{staticClass:"mv_contents"},[_c('h1',{staticClass:"mv_title"},[_vm._v("パスワード再設定")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form_lblWrap"},[_c('label',{staticClass:"form_lbl form_lbl-caution u-ttlBar"},[_vm._v("パスワード")]),_c('p',{staticClass:"form_caution"},[_vm._v(" ※8桁以上、3種類（英字大文字、英字小文字、数字）を含めてください。 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"errField",staticStyle:{"display":"none"},attrs:{"id":"err-pass-login-field"}},[_c('p',{staticClass:"errField_text"})])}]

export { render, staticRenderFns }